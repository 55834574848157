<template>
	<div class="hj_ftpic">
		<div class="ydpic ydp1" style="position: absolute; left: 0; top: 50%; margin-top: -100px;
	            z-index: 10; width: 40%;">
			<a href="javaScript:void(0)" @click.prevent="goIndex()">
				<img src="./img/yd1.jpg" /><span><img src="./img/yd1a.jpg" /></span></a>
		</div>
		<div class="ydpic ydp2" style="position: absolute; right: 0; top: 50%; margin-top: -100px;
	            z-index: 10; width: 40%;">
			<a href="http://www.umfintech.com" target="_blank">
				<img src="./img/yd2.jpg" /><span><img src="./img/yd2a.jpg" /></span></a>
		</div>
		<div class="ydlogo">
			<img src="./img/yd_logo.png" />
		</div>
		<div style="position: absolute; bottom: 50px; font-weight: bold; left: 0; right: 0;
	            text-align: center; font-size: 12px; line-height: 24px;">
			海联金汇 Copyright © 2017 <a href="//beian.miit.gov.cn/#/Integrated/index">鲁ICP备20013248号</a><br />
			<a href="//beian.miit.gov.cn/#/Integrated/index">信息产业部备案管理系统网址(https://beian.miit.gov.cn/)</a><br />
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goIndex() {
				this.$router.push({
					path: '/index'
				})
			}
		},
	}
</script>

<style scoped lang="scss">
	.hj_ftpic {
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: url(./img/yd_bg.jpg) no-repeat center center;
		animation: 20s linear 0s normal none infinite running vd_dh;
	}

	@keyframes vd_dh {
		0% {
			background-size: 100% 100%;
		}

		50% {
			background-size: 120% 120%;
		}

		100% {
			background-size: 100% 100%;
		}
	}

	@-moz-keyframes vd_dh {
		0% {
			background-size: 100% 100%;
		}

		50% {
			background-size: 120% 120%;
		}

		100% {
			background-size: 100% 100%;
		}
	}

	@-o-keyframes vd_dh {
		0% {
			background-size: 100% 100%;
		}

		50% {
			background-size: 120% 120%;
		}

		100% {
			background-size: 100% 100%;
		}
	}

	.ydpic img {
		width: 100%;
	}

	.ydlogo {
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 9;
		top: 50%;
		margin: -100px auto 0;
		width: 20%;
	}

	.ydlogo img {
		max-width: 80%;
	}

	.ydpic a {
		display: block;
		position: relative;
	}

	.ydpic span {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 11;
		width: 100%;
		opacity: 0;
	}

	.ydpic a:hover span {
		opacity: 1;
	}
</style>