<template>
    <div class="pc_index_footer">
        <div class="top_box">
            <div class="parent_ul">
                <template v-for="(item, index) in $store.state.menus">

                    <div class="parent_li" v-if="item.menus.length > 0" :key="item.mid">
                        <div class="parent_title">
                            {{ item.name }}
                        </div>
                        <div class="children_ul">
                            <div class="children_li" v-for="(it, i) in item.menus" :key="i">
                                <div class="child_title" v-if="it.mid != 'xincailiao'">
                                    <a :target="it.pmkey == 'commerce' ? '_blank' : '_self'" :href="it.url"
                                        @click="changeTabs(it, index)">
                                        {{ it.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

            </div>

            <img class="qrcode" :src="$store.state.info.qrcode" alt="">
        </div>
        <div class="bottom_box">
            <div class="bottom_left">
                <div class="font_one">
                    Copyright©{{year}} 海联金汇科技股份有限公司 版权所有
                </div>
                <div style="display: flex;">
                    <div class="font_two">
						<a class="font_three" target="_blank" href="https://beian.miit.gov.cn/">鲁ICP备20013248号</a>
                    </div>
                </div>
            </div>
            <div class="bottom_right">
                <a class="router" href="/legal">法律声明</a>
                <span style="margin:0 5px;"> | </span>
                <a class="router" href="/privacy">隐私保护</a>
                <span style="margin:0 5px;"> | </span>
                <a class="router" href="/relation">联系我们</a>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
			year: ''
        }
    },
    created() {
		this.year = new Date().getFullYear()
        // this.$store.dispatch('getHotProduct')
    },
    mounted() {
    },
    methods: {
        changeTabs(cit, pid) {

            this.$store.commit('changeMenus', pid)
        }
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import "@/utils/css/mixin.scss";

.pc_index_footer {
    width: 100%;
    padding: var(--padding);
    padding-bottom: 0;
    @include font(14, 20, #4D4D4D, 400);
    box-sizing: border-box;
    background: var(--bg-colors-grey);

    .top_box {
        width: 100%;
        @include justify(space-between);
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 50px;

        .parent_ul {
            width: 65%;
            @include justify(space-between);

            .parent_li {
                // min-width: 10%;

                .parent_title {
                    font-family: 'PingFangSC-Semibold, PingFang SC';
                    @include font(18, 50, #1A1A1A, 600);
                    margin-bottom: 10px;
                }

                .children_ul {
                    .children_li {
                        font-family: 'PingFangSC-Semibold, PingFang SC';
                        @include font(18, 50, #1A1A1A, 400);

                        .child_title {
                            a {
                                color: #4D4D4D;

                                &:hover {
                                    color: var(--bg-colors);
                                    cursor: pointer;
                                }
                            }


                        }
                    }
                }
            }


        }

        .qrcode {
            @include height-width(200, 200);
        }
    }

    .bottom_box {
        padding: 20px 0;
        @include justify-align(space-between, flex-end);

        .bottom_left {
            width: 65%;
            @include font(16, 35, #999, 400);

            .font_two:hover,
            .font_one:hover {
                color: var(--bg-colors);
            }

            .font_one {
                display: inline-block;
            }

            .font_three {
                // margin-left: 5px;
                @include font(16, 35, #999, 400);

                &:hover {
                    color: var(--bg-colors);
                }
            }
        }

        .bottom_right {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .router {
                @include font(16, 35, #4D4D4D, 400);

                &:hover {
                    color: var(--bg-colors);
                    cursor: pointer;
                }
            }

        }
    }


}
</style>
